<template>
	<span></span>
</template>

<script>
export default {
	// 空白页
	name: "blank"
}
</script>

<style scoped>

</style>
